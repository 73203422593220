.profileContainer {

}

.profileContentBox {
  width: 100%;
  //height: calc(100vh - 180px);
  height: 100vh;
  display: flex;
  justify-content: center;
}

.backgroundHeader {
  width: 100%;
  height: 150px;
  background-image: url('../../assert/img/backgroundImg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;

}

.profileContent {
  width: 100%;
  height: 100%;
  background: rgba(245, 245, 245, 0.5);

  overflow-y: auto;
}

.profileSetting {
  width: 1238px;
  height: 100%;
  background: rgba(245, 245, 245, 0.5);
}

.footer {
  width: 100%;
  height: 60px;
  margin-top: 20px;
  border-top: 1px solid #eeeeee;


  .footerBox {
    display: flex;
    max-width: 600px;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    height: 100%;
    .leftBox {
      img {
        height: 24px;
      }
    }
    .rightBox {
      width: 340px;
      display: flex;
      justify-content: space-between;
      color: #fff;
      a {
        text-decoration: none;
      }
    }
  }

}